<template>
  <div class="wrapper">

      <Menu></Menu>

    <div class="body" style="padding: 0px 13px;">

      <div class="row">

        <div class="casino-headers col-12">
          <span>Endorphina Games</span>
        </div>

        <div class="col-auto" v-for="(o,index) in games" :key="getKey(index)" style="text-align: center;font-weight: 700;padding: 1px; min-width: 20%">

          <div style="margin: 1px;">

            <img src="/assets/images/3-thurnders.png" class="c-image" width="115" style="border-radius: 10px"/>

            <div class="row" style="margin: -70px 10px -10px 10px; background-color: white; color: var(--primary-dark)" >

              <router-link :to="{ name: 'endorphinagames', params: { gameID: o.game_id,gameName: o.game_name,gameURL: o.game_url,playMode: 'demo' }}" class="col-12 play-btn" style="background-color: white;color: var(--primary-dark);">
                Demo
              </router-link>

              <router-link :to="{ name: 'endorphinagames', params: { gameID: o.game_id,gameName: o.game_name,gameURL: o.game_url,playMode: 'real' }}" class="col-12 play-btn" style="background-color: white;color: var(--primary-dark);">
                Real
              </router-link>
            </div>

            <br/>
            <span class="game-name">
                <span v-text="o.game_name"></span>
            </span>
          </div>

        </div>
      </div>

      <BottomFooter></BottomFooter>
      <bottom-navigation active-bets="0" page="freebet"></bottom-navigation>
    </div>

  </div>
</template>
<script>
import endorphina from "@/services/endorphina";

const Menu = () => import("@/components/casino/Menu.vue");
import BottomFooter from "@/components/BottomFooter";
import BottomNavigation from "@/components/BottomNavigation";


export default {
  name: 'Endorphina',
  components: {
    Menu,
    BottomFooter,
    BottomNavigation,
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: '',
      payout: '',
      tax: '',
      odd_id: '',
      outcome: {},
      msisdn: '',
      password:'',
      loading:'',
      code:'',
      action_verify_password: false,
      action_success: false,
      message: '',
      games: [],
      groupedGames: [
        {
          name: '',
          games: []
        }
      ]
    }
  },
  mounted() {

    this.reloadProfile();
    this.$store.dispatch("setCurrentPage","endorphina");

    var vm = this;

    var path = process.env.VUE_APP_ENDORPHINA_GAMES_URL;

    endorphina.post(path, JSON.stringify({}))
        .then(res => {

          vm.loading = '';
          vm.games = res.data;
        })
        .catch(err => {

          vm.loading = '';

          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.message)
            console.log(JSON.stringify(err.response.data.message));

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }

        })

  },
  computed: {

    app: function () {

      return this.$store.state.app;

    },

    profile: function() {

      return this.getProfile();

    },
    token: function(){

      return this.getAuth();
    },

  },
  methods: {

    getKey: function(index){

      return Math.random().toString(10).replace('0.', 'games-id-' +index+ '-');

    },

    getGameURL: function (gameName,categoryDesktop,categoryMobile) {

      var tokn = this.getFasoAuth();
      var PortalName = "fasobet"

      var category = this.app ? categoryMobile : this.isMobile() ? categoryMobile : categoryDesktop

      if(tokn === undefined || tokn === null || !tokn || tokn.length === 0 || tokn.length < 5 ) {

        tokn = "DEMO";
        PortalName = "demo"
      }

      var src = "https://eu-server.ssgportal.com/GameLauncher/Loader.aspx?GameCategory="+ category +"&GameName="+ gameName +"&Token=" + tokn + "&PortalName=" + PortalName + "&lang=en&returnUrl=https://tapabet.co.ke/login&Lang=en"

      console.log('GAME URL '+src)

      return src

    }

  },
  filters: {

    amount: function (val){

      if(val === undefined ) {

        return val

      }

      return parseFloat(val).toFixed(2).toLocaleString()

    }

  }
}
</script>